import { salesStaticService } from '@/root/shared-api'
import { useRouter } from 'next/router'
import useSWR from 'swr'

export function useMenuItems() {
  const { locale } = useRouter()
  const { data, error } = useSWR(`/menu-items/${locale}`, () => salesStaticService.getMenuItems(locale as string))
  return {
    menuItems: data,
    error,
  }
}
