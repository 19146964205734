import axios, { AxiosInstance } from 'axios'

const appInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

const cmsInstance: AxiosInstance = axios.create({
  baseURL: process.env.CMS_API_BASE || process.env.FALLBACK_CMS_API_BASE,
})

const apiInstance: AxiosInstance = axios.create({
  baseURL: process.env.API_BASE || process.env.FALLBACK_API_BASE,
  headers: {
    'X-Bora-Tenant': process.env.TENANT || process.env.FALLBACK_TENANT,
  },
  auth: {
    username: process.env.API_USERNAME as string,
    password: process.env.API_PASSWORD as string,
  },
})

export { appInstance, cmsInstance, apiInstance }
