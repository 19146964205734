import { axios } from '@/configs'
import { Reservation, Sails } from '@/root/shared-types'

class ReservationService {
  async getReservation(token: string, locale: string) {
    const { data: reservation } = await axios<Reservation>({
      url: `reservation/token/${token}`,
      method: 'get',
      headers: { 'Accept-Language': locale },
    })
    return reservation
  }

  async refundReservation(reservationId: number) {
    const { data: reservation } = await axios<Reservation>({
      url: `reservation/${reservationId}/refund`,
      method: 'post',
    })
    return reservation
  }

  async refundReservationSails(reservationId: number, sailRefIds: [number, number?]) {
    const { data: sails } = await axios<Sails>({
      url: `reservation/${reservationId}/refund/sails`,
      method: 'post',
      data: { sailRefIds },
    })
    return sails
  }
}

const reservationService = new ReservationService()

export default reservationService
