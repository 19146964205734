/* eslint-disable react/prop-types */
import { FooterItems, MenuItem, PageProps } from '@/root/shared-types'
import { GetStaticProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import { cmsAxios } from '@/configs'
import { admaritime, bluewave } from '@/root/shared-consts/theme'
import { useConfig } from '@/root/shared-hooks'

const AdmaritimeMainPage = dynamic(() => import('@/root/pages/admaritime').then((module) => module.MainPage), {
  ssr: false,
})
const BluewaveMainPage = dynamic(() => import('@/root/pages/bluewave').then((module) => module.MainPage))

const MainPage: NextPage<PageProps> = ({ footerItems, menuItems }) => {
  const { theme } = useConfig()
  switch (theme) {
    case admaritime:
      return <AdmaritimeMainPage footerItems={footerItems} menuItems={menuItems} />
    case bluewave:
      return <BluewaveMainPage footerItems={footerItems} menuItems={menuItems} />
    default:
      return null
  }
}

export const getStaticProps: GetStaticProps<PageProps> = async ({ locale }) => {
  try {
    const { data } = await cmsAxios.get<{ content: FooterItems }>('/footer', {
      params: { _locale: locale },
    })
    const footerItems = data.content

    const { data: menuItems } = await cmsAxios.get<MenuItem[]>('/menu-items', {
      params: { _locale: locale },
    })

    return {
      props: {
        footerItems,
        menuItems: menuItems.sort(({ order: prevOrder }, { order: nextOrder }) => prevOrder - nextOrder),
      },
      revalidate: 60,
    }
  } catch (e) {
    return {
      props: {
        footerItems: {
          lower: [],
          social: [],
          upper: [],
        },
        menuItems: [],
      },
    }
  }
}

export default MainPage
