import {
  getDepartureAndArrivalDataFromReservation,
  getItemsFromReservation,
  getNumOfPriceCategorySubTypesFromItems,
  getSailRefIdsFromReservation,
  getVehiclesFromItemsAndReservation,
  getVesselTitleFromReservation,
  getRefundStatusesFromReservation,
} from '@/root/shared-helpers'
import { useReservation } from './useReservation'
import { TicketData } from '@/root/shared-types/reservation/reservation-types'

export function usePrintedTicketData(): {
  reservation: TicketData | undefined
  error: Error | undefined
  isLoading: boolean
} {
  const { reservation, error, isLoading } = useReservation()

  if (!reservation) {
    return { error, isLoading, reservation }
  }

  const { reservationId, reservationOwner } = reservation

  const {
    email,
    fullName,
    phones: [{ intlCode, phoneNumber }],
  } = reservationOwner

  const {
    departureThere,
    departurePortThere,
    arrivalThere,
    arrivalPortThere,
    departureBack,
    departurePortBack,
    arrivalBack,
    arrivalPortBack,
  } = getDepartureAndArrivalDataFromReservation(reservation)

  const { itemsThere, itemsBack } = getItemsFromReservation(reservation)

  const { vesselTitleBack, vesselTitleThere } = getVesselTitleFromReservation(reservation)

  const { numOfCars: numOfCarsThere, numOfPassengers: numOfPassengersThere } =
    getNumOfPriceCategorySubTypesFromItems(itemsThere)

  const { numOfCars: numOfCarsBack, numOfPassengers: numOfPassengersBack } =
    getNumOfPriceCategorySubTypesFromItems(itemsBack)

  const vehiclesThere = getVehiclesFromItemsAndReservation(reservation, itemsThere)
  const vehiclesBack = getVehiclesFromItemsAndReservation(reservation, itemsBack)

  const { sailRefIdThere, sailRefIdBack } = getSailRefIdsFromReservation(reservation)

  const { isRefundThere, isRefundBack } = getRefundStatusesFromReservation(reservation)

  return {
    reservation: {
      there: {
        departure: departureThere,
        departurePort: departurePortThere,
        arrival: arrivalThere,
        arrivalPort: arrivalPortThere,
        items: itemsThere,
        vesselTitle: vesselTitleThere,
        numOfCars: numOfCarsThere,
        numOfPassengers: numOfPassengersThere,
        vehicles: vehiclesThere,
        sailRefId: sailRefIdThere,
        isRefund: isRefundThere,
      },
      back: {
        departure: departureBack,
        departurePort: departurePortBack,
        arrival: arrivalBack,
        arrivalPort: arrivalPortBack,
        items: itemsBack,
        vesselTitle: vesselTitleBack,
        numOfCars: numOfCarsBack,
        numOfPassengers: numOfPassengersBack,
        vehicles: vehiclesBack,
        sailRefId: sailRefIdBack,
        isRefund: isRefundBack,
      },
      customerInfo: { email, fullName, phoneNumber: `+${intlCode} ${phoneNumber}` },
      reservationId,
      isRoundTrip: reservation.sailPackages.length === 2,
      status: reservation.status,
      terms: reservation.terms,
      paymentInfo: reservation.paymentInfo,
    },
    error,
    isLoading,
  }
}
