import { salesStaticService } from '@/root/shared-api'
import { useRouter } from 'next/router'
import useSWR from 'swr'

export function useFooterItems() {
  const { locale } = useRouter()
  const { data, error } = useSWR(`/footer/${locale}`, () => salesStaticService.getFooterItems(locale as string))
  return {
    footerItems: data,
    error,
  }
}
