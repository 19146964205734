import { axios } from '@/configs'
import { FooterItems, MenuItem } from '@/root/shared-types'

class SalesStaticService {
  async getFooterItems(locale: string) {
    const { data: footerItems } = await axios<FooterItems>({
      method: 'get',
      url: 'sales-static/footer',
      headers: { 'Accept-Language': locale },
    })
    return footerItems
  }

  async getMenuItems(locale: string) {
    const { data: menuItems } = await axios<MenuItem[]>({
      url: 'sales-static/menu-items',
      method: 'get',
      headers: { 'Accept-Language': locale },
    })
    return menuItems
  }
}

const salesStaticService = new SalesStaticService()

export default salesStaticService
