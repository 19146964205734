import { reservationService } from '@/root/shared-api'
import { useRouter } from 'next/router'
import useSWR from 'swr/immutable'
import { Reservation } from '@/root/shared-types'

export function useReservation() {
  const {
    query: { reservationToken },
    isReady,
    locale,
  } = useRouter()

  const { data, error, isLoading } = useSWR<Reservation>(isReady && `/reservation/${reservationToken}/${locale}`, () =>
    reservationService.getReservation(reservationToken as string, locale as string)
  )

  return {
    reservation: data,
    error,
    isLoading,
  }
}
