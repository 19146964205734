import { Locale, Reservation, ReservationItem, SailPackage, Terms } from '@/root/shared-types'

export function getTermsByLocale(terms: Terms[], locale: Locale) {
  return terms.find(({ language }) => language === locale) || terms[0]
}

export function getVesselTitleFromReservation(reservation: Reservation) {
  const {
    sailPackages: [sailPackageThere, sailPackageBack],
  } = reservation
  const {
    sailRefs: [{ vesselTitle: vesselTitleThere }],
  } = sailPackageThere
  const { sailRefs: [{ vesselTitle: vesselTitleBack = '' }] = [{}] } = sailPackageBack || {}
  return {
    vesselTitleThere,
    vesselTitleBack,
  }
}

export function getItemsWithoutFee(items: ReservationItem[]) {
  return items.filter(({ type }) => type !== 'FEE')
}

export function getItemsFromReservation(reservation: Reservation) {
  const {
    sailPackages: [sailPackageThere, sailPackageBack],
    items,
  } = reservation
  const { seqN: seqNThere } = sailPackageThere
  const { seqN: seqNBack } = sailPackageBack || {}

  const itemsWithoutFee = getItemsWithoutFee(items)

  const itemsThere = itemsWithoutFee.filter(({ sailPackageSeqN }) => {
    return sailPackageSeqN === seqNThere
  })

  const itemsBack = itemsWithoutFee.filter(({ sailPackageSeqN }) => {
    return sailPackageSeqN === seqNBack
  })

  return {
    itemsThere,
    itemsBack,
  }
}

export function getNumOfPriceCategorySubTypesFromItems(items: ReservationItem[]) {
  return items.reduce(
    (acc, { priceCategorySubType, quantity }) => {
      if (priceCategorySubType === 'DECK') {
        acc.numOfPassengers += quantity
      }

      if (priceCategorySubType === 'VEHICLE') {
        acc.numOfCars += quantity
      }

      return acc
    },
    { numOfPassengers: 0, numOfCars: 0 }
  )
}

export function getVehiclesFromItemsAndReservation(reservation: Reservation, items: ReservationItem[]) {
  const { vehicles } = reservation

  return (
    vehicles &&
    vehicles.filter(({ seqN }) => {
      return items.some(({ ownerSeqNs }) => ownerSeqNs.includes(seqN))
    })
  )
}

export function getSailRefIdsFromReservation(reservation: Reservation) {
  const {
    sailPackages: [sailPackageThere, sailPackageBack],
  } = reservation
  const {
    sailRefs: [{ sailRefId: sailRefIdThere }],
  } = sailPackageThere
  const { sailRefs: [{ sailRefId: sailRefIdBack = 0 }] = [{}] } = sailPackageBack || {}
  return {
    sailRefIdThere,
    sailRefIdBack,
  }
}

export function getDepartureAndArrivalFromSailPackage(sailPackage?: SailPackage) {
  const [{ arrivalAt }] = sailPackage?.sailRefs?.slice(0, 1) || [{}]
  const [{ departureAt }] = sailPackage?.sailRefs?.slice(-1) || [{}]

  return {
    arrivalAt,
    departureAt,
  }
}

export function getPortsFromSailPackage(sailPackage?: SailPackage) {
  const { title = '' } = sailPackage || {}
  const [departurePort, arrivalPort] = title.split('-').map((port) => port.trim())

  return {
    departurePort,
    arrivalPort,
  }
}

export function getDepartureAndArrivalDataFromReservation(reservation: Reservation) {
  const {
    sailPackages: [sailPackageThere, sailPackageBack],
  } = reservation

  const { arrivalAt: arrivalThere, departureAt: departureThere } =
    getDepartureAndArrivalFromSailPackage(sailPackageThere)

  const { arrivalAt: arrivalBack, departureAt: departureBack } = getDepartureAndArrivalFromSailPackage(sailPackageBack)

  const { arrivalPort: arrivalPortThere, departurePort: departurePortThere } = getPortsFromSailPackage(sailPackageThere)

  const { arrivalPort: arrivalPortBack, departurePort: departurePortBack } = getPortsFromSailPackage(sailPackageBack)

  return {
    departureThere,
    departurePortThere,
    arrivalThere,
    arrivalPortThere,
    departureBack,
    departurePortBack,
    arrivalBack,
    arrivalPortBack,
  }
}

export function getRefundStatusesFromReservation(reservation: Reservation) {
  const {
    sailPackages: [sailPackageThere, sailPackageBack],
  } = reservation

  return {
    isRefundThere: !!sailPackageThere.refundOfferId,
    isRefundBack: !!sailPackageBack?.refundOfferId,
  }
}
